<template>
<v-dialog v-if="isOpen" :value="isOpen" max-width="500px">
    <v-card>
        <v-card-title>
            <span class="headline">{{ isEdit ? 'Edit message' : 'Create message' }}</span>
        </v-card-title>
        <v-card-text>
            <v-form>
                <v-text-field v-if="isEdit" :disabled="isEdit" v-model="localMessage" label="Message" :readonly="isEdit"></v-text-field>
                <v-text-field v-if="!isEdit" :disabled="isEdit" v-model="localMessage" label="Message" :readonly="isEdit" prefix="MSG-" type="number"></v-text-field>
                <v-textarea v-model="localNaziv" label="Naziv"></v-textarea>
            </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn color="blue darken-1" @click="save">Save</v-btn>
            <v-btn color="grey" @click="closeModal">Close</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    name: 'ProjectDetailModal',
    props: {
        model: {
            type: Object,
            required: true
        },
        isEdit: {
            type: Boolean,
            required: true
        },
        isOpen: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            localNaziv: this.model.MessageText,
            localMessage: this.model.MessageCode,
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        save(){
            if(this.isEdit){
                this.update();
            }else{
                this.create();
            }
        },
        update() {
            // Prepare the request URL
            const url = `https://sparkprize.azurewebsites.net/api/v1/flowmessage/${this.model.projectId}/${this.model.Id}/?apikey=${this.$apiKey}`;

            // Create the body of the request
            const requestBody = JSON.stringify({
                MessageCode: this.localMessage,
                MessageText: this.localNaziv,
                LanguageCode: this.model.languageCode
            });

            // Make the fetch request
            fetch(url, {
                    method: 'PUT', // Specify the method
                    headers: {
                        'Content-Type': 'application/json' // Specify the content type of the request body
                    },
                    body: requestBody // Include the JSON-encoded body
                })
                .then(response => {
                    if (response.ok) {
                        this.$emit('close');
                        return response.json(); // Parse JSON if the request was successful
                    } else {
                        throw new Error('Failed to update flow message'); // Throw an error if not successful
                    }
                })
                .then(data => {
                    console.log('Update successful:', data); // Log the successful update
                })
                .catch(error => {
                    console.error('Error updating flow message:', error); // Log any errors
                });
        },
        create() {
            // Prepare the request URL
            const url = `https://sparkprize.azurewebsites.net/api/v1/flowmessage/${this.$route.params.id}/?apikey=${this.$apiKey}`;

            // Create the body of the request
            const requestBody = JSON.stringify({
                MessageCode: "MSG-" + this.localMessage,
                MessageText: this.localNaziv,
                LanguageCode: 0
            });

            // Make the fetch request
            fetch(url, {
                    method: 'POST', // Specify the method
                    headers: {
                        'Content-Type': 'application/json' // Specify the content type of the request body
                    },
                    body: requestBody // Include the JSON-encoded body
                })
                .then(response => {
                    if (response.ok) {
                        this.$emit('close');
                        return response.json(); // Parse JSON if the request was successful
                    } else {
                        throw new Error('Failed to create flow message'); // Throw an error if not successful
                    }
                })
                .then(data => {
                    console.log('Create successful:', data); // Log the successful update
                })
                .catch(error => {
                    console.error('Error create flow message:', error); // Log any errors
                });
        }

    }
};
</script>

<style>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    max-width: 80%;
}

.modal-header,
.modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
