<template>
  <v-container>
    <h1>Dashboard</h1>
    <!-- <v-list>
      <v-list-item
        v-for="project in projects"
        :key="project.id"
        @click="goToProject(project.id)"
      >
        <v-list-item-content>
          <v-list-item-title>{{ project.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list> -->
  </v-container>
</template>

<script>
export default {
  data: () => ({
    projects: [
      { id: 1, name: 'Project A' },
      { id: 2, name: 'Project B' },
      { id: 3, name: 'Project C' }
    ],
    loaded: false,
  }),
  methods: {
    goToProject(id) {
      this.$router.push(`/project/${id}`);
    }
  }
}
</script>
