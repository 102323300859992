<template>
    <v-dialog v-model="dialog" persistent max-width="300px">
      <v-card>
        <v-card-title class="text-h5">Confirm Deletion</v-card-title>
        <v-card-text>Are you sure you want to delete this item?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="confirmDelete">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      value: Boolean,
      item: Object
    },
    computed: {
      dialog: {
        get() { return this.value; },
        set(value) { this.$emit('input', value); }
      }
    },
    methods: {
      close() {
        this.$emit('input', false);
      },
      confirmDelete() {
        this.$emit('confirmDelete', this.item);
        this.close();
      }
    }
  }
  </script>
  