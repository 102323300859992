import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'; // Make sure this path correctly points to your router file


Vue.config.productionTip = false

Vue.prototype.$apiKey = '741359ee-5a83-4037-9788-8c62f1b98ed2';

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
