<template>
  <v-app>
    <!-- Only show the app bar and sidebar if the user is logged in -->
    <v-app-bar v-if="loggedIn" app color="primary" dark elevate-on-scroll>
      <v-toolbar-title class="text-center" style="flex: 1;">SparkPrize</v-toolbar-title>
      <v-btn text @click="logout">Logout</v-btn>
    </v-app-bar>
    <v-navigation-drawer v-if="loggedIn" app v-model="drawer" permanent color="grey lighten-4">
      <v-list dense>
        <v-subheader style="justify-content: center; font-weight: 500; font-size: 24px;">Menu</v-subheader>
        <v-list-item @click="navigateTo('dashboard')" class="menu-item">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="navigateTo('projects')" class="menu-item">
          <v-list-item-icon>
            <v-icon>mdi-folder</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Projects</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: true
  }),
  computed: {
    loggedIn() {
      console.log('localStorage', localStorage);
      return localStorage.getItem('user') !== null;
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('user');
      this.$router.push('/');
      window.location.reload();
    },
    navigateTo(route) {
      this.$router.push({ name: route });
    }
  }
}
</script>

<style>
.menu-item {
  transition: background-color 0.3s;
}

.menu-item:hover {
  background-color: #94eaff;
}
</style>
