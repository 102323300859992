import Vue from 'vue';
import Router from 'vue-router';
import LoginForm from '@/components/LoginForm';
import UserDashboard from '@/views/UserDashboard';
import ProjectList from '@/views/Project/ProjectList';
import ProjectDetail from '@/views/Project/ProjectDetail';


Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch(err => {
    if (err.name === 'NavigationDuplicated') {
      // Handle error by returning the error instance
      return err;
    }
    // Re-throw other types of errors
    throw err;
  });
};

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'login',
      component: LoginForm,
      beforeEnter: (to, from, next) => {
        if (localStorage.getItem('user')) {
          next({ name: 'dashboard' }); // Redirect to dashboard if user is logged in
        } else {
          next(); // Proceed to login page if not logged in
        }
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: UserDashboard,
      meta: { requiresAuth: true }
    },
    {
      path: '/projects',
      name: 'projects',
      component: ProjectList,
      meta: { requiresAuth: true }
    },
    {
      path: '/projects/:id',  // Add a new route for project details
      name: 'project',
      component: ProjectDetail,
      meta: { requiresAuth: true }
    }
  ]
});

// Global Navigation Guard for Authentication
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = localStorage.getItem('user');

  if (requiresAuth && !isAuthenticated) {
    next({ name: 'login' }); // Redirect to login if not authenticated
  } else {
    next(); // Otherwise proceed as normal
  }
});

export default router;
