<template>
<v-container>
    <h1 class="pa-5">{{ project }}</h1>
    <v-tabs>
        <v-tab>Basic</v-tab>
        <v-tab>Messages</v-tab>

        <v-tab-item>
            <v-card class="form-container">
                <v-form ref="form">
                    <v-card-text>
                        <div style="display: flex;">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" class="mr-2 mb-5">mdi-information</v-icon>
                                </template>
                                <span>Intent is a sequence of words that the user can enter to interact with the project data.</span>
                            </v-tooltip>
                            <v-text-field v-model="intent" label="Intents" class="mr-2 mt-1" outlined dense :rules="[rules.noSpaces]"></v-text-field>
                        </div>

                    </v-card-text>
                    <v-card-actions class="button-container">
                        <v-btn class="ma-5" color="primary" @click="saveBasic">Save</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-tab-item>

        <v-tab-item>
            <div class="button-container">
                <v-btn class="ma-5" color="primary" @click="openModalForCreation">Add message</v-btn>
            </div>
            <v-data-table :headers="headers" :items="messages" class="elevation-1" :items-per-page="messages.length" hide-default-footer>
                <template v-slot:item="{ item }">
                    <tr @click="openModalForEdit(item)">
                        <td>{{ item.MessageCode }}</td>
                        <td>{{ item.MessageText }}</td>
                        <td class="text-right">
                            <v-icon small @click.stop="openDeleteModal(item)">
                                mdi-delete
                            </v-icon>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-tab-item>
    </v-tabs>

    <project-detail-modal v-if="isModalOpen" :isOpen="isModalOpen" :model="message" :isEdit="isEdit" @close="closeModal" />
    <confirm-delete-modal v-model="showDeleteModal" :item="selectedItem" @confirmDelete="deleteItem" />

</v-container>
</template>

<script>
import ProjectDetailModal from './Components/ProjectDetailModal';
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal';
export default {
    data() {
        return {
            messages: [],
            message: {},
            project: null,
            intent: null,
            isModalOpen: false,
            modalNaziv: '',
            modalMessage: '',
            projectId: null,
            isEdit: false,
            showDeleteModal: false,
            selectedItem: null,
            headers: [{
                    text: 'Message Code',
                    value: 'messageCode',
                    align: 'start'
                },
                {
                    text: 'Message Text',
                    value: 'messageText',
                    align: 'start'
                }
            ],
            rules: {
                noSpaces: value => {
                    const pattern = /^([^,\s]*,)*[^,\s]*$/;
                    return pattern.test(value) || 'Input should not contain spaces, only comma separated values';
                }
            }
        };
    },
    components: {
        ProjectDetailModal,
        ConfirmDeleteModal
    },
    created() {
        this.projectId = this.$route.params.id;
        const project = localStorage.getItem('project');
        const projectObj = JSON.parse(project);
        this.project = projectObj.project;
        this.intent = projectObj.intent;
        this.loadMessages();
    },
    methods: {
        openModalForCreation() {
            this.isEdit = false;
            this.message = {
                MessageText: null,
                MessageCode: null,
                projectId: null,
                Id: null,
                LanguageCode: null
            }
            this.isModalOpen = true;
        },
        openModalForEdit(item) {
            this.isEdit = true;
            this.message = {
                MessageCode: item.MessageCode,
                MessageText: item.MessageText,
                projectId: this.projectId,
                Id: item.Id,
                LanguageCode: item.LanguageCode
            }

            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
            this.loadMessages();
        },
        loadMessages() {
            fetch('https://sparkprize.azurewebsites.net/api/v1/flowmessage/' + this.projectId + '/?apikey=' + this.$apiKey)
                .then((response) => response.text()) // Use text() to log the raw response
                .then((text) => {
                    this.messages = JSON.parse(text); // Then parse the JSON
                })
                .catch((error) => {
                    console.error("Error fetching projects:", error);
                });
        },
        openDeleteModal(item) {
            this.selectedItem = item;
            this.showDeleteModal = true;
        },
        deleteItem(item) {
            const url = 'https://sparkprize.azurewebsites.net/api/v1/flowmessage/' + this.projectId + '/' + item.Id + '/?apikey=' + this.$apiKey;
            fetch(url, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to delete the flow message');
                    }
                    this.loadMessages();
                    return response.json(); // or `response.text()` if no JSON response
                })
                .then(() => {
                    console.log('Flow message deleted successfully');
                    this.loadMessages(); // Assuming you have a method to refresh the list after deletion
                })
                .catch(error => {
                    console.error('Error deleting flow message:', error);
                });
        },
        saveBasic() {
            if (!this.$refs.form.validate()) {
                console.error('Data is not valid:');
                return;
            }
            const url = 'https://sparkprize.azurewebsites.net/api/v1/project/' + this.projectId + '/info?apikey=' + this.$apiKey;
            const requestBody = {
                Intent: this.intent
            }
            fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestBody)
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to save project info');
                    }
                    console.log('Project info saved successfully');
                })
                .catch(error => {
                    console.error('Error saving project info:', error);
                });
        }
    }
};
</script>

<style>
.v-data-table {
    cursor: pointer;
}

.v-chip {
    background-color: #1976d2;
    color: white;
}

.button-container {
    display: flex;
    justify-content: flex-end;
}
</style>
