<template>
<v-container fluid>
    <!-- Using Flexbox to center the form both vertically and horizontally -->
    <v-row justify="center" align="center" style="height: 100vh;">
        <v-col cols="12" sm="8" md="4">
            <!-- Using a card for better visual structure -->
            <v-card class="elevation-12" outlined>
                <v-card-title class="text-h5">Login</v-card-title>
                <v-card-text>
                    <v-form>
                        <v-text-field label="Email" v-model="email" type="email" required></v-text-field>
                        <v-text-field label="Password" v-model="password" type="password" required></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- Button to trigger login -->
                    <v-btn color="primary" @click="login">Login</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    data: () => ({
        email: '',
        password: ''
    }),
    methods: {
        login() {
            if (this.email === 'admin' && this.password === 'adminspass') {
                localStorage.setItem('user', JSON.stringify({
                    email: this.email
                }));

                this.$router.push('/dashboard');
                window.location.reload();
            } else {
                alert('Invalid credentials');
            }
        }
    }
}
</script>
